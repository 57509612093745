const axios = require('axios').default;

/**
 * ExchangeRates service api
 * @class ExchangeRatesService
 */
class ExchangeRatesService {
  constructor() {
    this.apiKey = process.env.VUE_APP_EXCHANGE_RATE_API_KEY || '';
    this.baseUrl = `https://freecurrencyapi.net/api/v2/latest?apikey=${this.apiKey}`;
  }

  /**
   * Get all app settings from
   * @function getAll
   * @return {Promise.<GrapePerksApiResponse | any>}
   */
  getUsdToAudExchangeRate = () => axios.get(`${this.baseUrl}&base_currency=USD`)
    .then((resp) => resp.data.data.AUD);
}

const singletonInstance = new ExchangeRatesService();

/**
 * exchange rate service api instance
 * @exports api/exchange-rate
 * */
export default singletonInstance;
