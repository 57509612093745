<template>
  <div class="vx-card p-6">
    <form @submit.prevent>
      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full mb-2">
          <vs-input
            v-model.number="model.usdExchangeRate"
            class="w-full"
            type="number"
            :label="$t('UsdExchangeRate')"
            :name="$t('UsdExchangeRate')"
            v-validate="'required'"
            data-vv-validate-on="blur|input"
            :disabled="!editing"/>
          <span
            v-show="errors.has($t('UsdExchangeRate'))"
            class="text-danger text-sm">
            {{ errors.first($t('UsdExchangeRate')) }}
          </span>
          <div class="w-full d-flex justify-between items-center">
            <small>
              {{
                $t('RealTimeExchange', { rate: realTimeUsdExchangeRate })| lowercase
              }}
            </small>

            <vx-tooltip
              :text="$t('$General.Refresh') | lowercase"
              position="top"
              class="inline-block cursor-pointer ml-3">
              <feather-icon
                icon="RefreshCwIcon"
                svgClasses="w-5 h-4 text-primary"
                @click="getUsdToAudExchangeRate()"/>
            </vx-tooltip>
            <vx-tooltip
              v-if="editing && realTimeUsdExchangeRate"
              :text="$t('$General.Copy') | lowercase"
              position="top"
              class="inline-block cursor-pointer ml-1">
              <feather-icon
                icon="CopyIcon"
                svgClasses="w-5 h-4 text-primary"
                @click="model.usdExchangeRate=realTimeUsdExchangeRate"/>
            </vx-tooltip>
          </div>
        </div>

        <div class="vx-col sm:w-1/2 w-full mb-2">
          <vs-input
            v-model.number="model.monthlyDiscountServices"
            class="w-full"
            type="number"
            :label="$t('MonthlyDiscountForServices')"
            :name="$t('MonthlyDiscountForServices')"
            v-validate="'required|min_value:0|max_value:100'"
            data-vv-validate-on="blur|input"
            :disabled="!editing"/>
          <span
            v-show="errors.has($t('MonthlyDiscountForServices'))"
            class="text-danger text-sm">
          {{ errors.first($t('MonthlyDiscountForServices')) }}
        </span>
        </div>

        <div class="vx-col sm:w-1/2 w-full mb-2">
          <vs-input
            v-model.number="model.gst"
            class="w-full"
            type="number"
            :label="$t('GST')"
            :disabled="!editing"/>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full flex">
          <vs-switch
            v-model="model.stopSendingCampaigns"
            :disabled="!editing"/>
          <span>
             Stop Sending campaigns
          </span>
        </div>
      </div>

      <vx-card
        collapse-action
        no-shadow
        card-border
        :title="$t('SmsProvidersStatus')"
        class="mt-3">
        <ul>
          <li
            v-for="(value, key) in model.providersSwitch"
            :key="key"
            class="flex my-3">
            <span class="mr-5">{{ key | title }}</span>
            <vs-switch
              v-model="model.providersSwitch[key]"
              :disabled="!editing"/>
          </li>
        </ul>
      </vx-card>

      <vx-card
        collapse-action
        no-shadow
        card-border
        :title="$t('PackagesInformation')"
        class="mt-3">
        <vs-table
          id="the-app-settings-packages"
          :data="model.packages">
          <template slot="thead">
            <vs-th>
              {{ $t('$General.NumberAbbreviation') }}
            </vs-th>
            <vs-th>
              {{ $t('$General.PriceInCurrency', { currency: 'AUD'}) }}
            </vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr
              v-for="(packageOption, index) in data"
              :key="index"
              :data="packageOption">
              <vs-td>
                <p>{{ index + 1 }}</p>
              </vs-td>

              <vs-td>
                <vs-input
                  v-model.number="data[index]"
                  type="number"
                  min="0"
                  icon-pack="feather"
                  icon="icon-dollar-sign"
                  icon-no-border
                  :disabled="!editing"/>
              </vs-td>
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>

      <div class="vx-row mt-3">
        <div class="vx-col w-full flex justify-around">
          <vs-button
            v-if="editing"
            class="mr-3 mb-2"
            :disabled="!validateForm"
            @click.prevent="confirmSaveSettings()">
            {{ $t('$General.Save') }}
          </vs-button>
          <vs-button
            v-if="editing"
            color="warning"
            type="border"
            class="mb-2"
            @click="cancelEdit()">
            {{ $t('$General.Cancel') }}
          </vs-button>
          <vs-button
            v-if="!editing"
            color="warning"
            type="border"
            class="mb-2"
            @click="editing=true">
            {{ $t('$General.Edit') }}
          </vs-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import exchangeRatesService from '@/api/exchange-rates.service';
import appSettingsService from '@/api/modules/app-settings.service';
import AppSettingsConstructor from '@/views/modules/app-settings/app-settings.constructor';

/**
 * Component to view and update the app settings
 *
 * @module views/TheAppSettings
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {string} [entity='AppSettings'] - Name of the entity associated
 * @vue-data {Object} model - model to save
 * @vue-data {Object} modelPayload - payload model
 * @vue-data {Number} realTimeUsdExchangeRate - real time usd to aud exchange rate
 * @vue-data {boolean} editing - indicate if the settings are been updated
 * @vue-computed {boolean} validateForm - verify is form to save is valid
 * @vue-event {void} created - hook to get initial data
 * @vue-event {void} getUsdToAudExchangeRate - get usd to aud exchange rate
 * @vue-event {void} getAppSettings - get current app settings
 * @vue-event {void} cancelEdit - call on cancel edit
 * @vue-event {void} setSettingToDefault - set local settings to the current app settings
 * @vue-event {void} confirmSaveSettings - call to confirm save updated settings
 * @vue-event {void} saveSettings - save updated settings
 */
export default {
  name: 'TheAppSettings',
  i18n: {
    messages: {
      en: {
        UsdExchangeRate: 'USD exchange rate',
        RealTimeExchange: 'Real time exchange rate: {rate}',
        MonthlyDiscountForServices: 'Monthly discount for services(%)',
        GST: 'GST(%)',
        ConfirmSaveText: 'You are about to update the app settings',
        PackagesInformation: 'Packages information',
        SmsProvidersStatus: 'SMS providers status',
      },
    },
  },
  data() {
    return {
      entity: this.$enums.Entity.APP_SETTINGS,
      model: new AppSettingsConstructor(),
      modelPayload: null,
      realTimeUsdExchangeRate: null,
      editing: false,
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  created() {
    this.getUsdToAudExchangeRate();
    this.getAppSettings();
  },
  methods: {
    async getUsdToAudExchangeRate() {
      try {
        this.realTimeUsdExchangeRate = await exchangeRatesService.getUsdToAudExchangeRate();
      } catch (e) {
        this.realTimeUsdExchangeRate = null;
      }
    },
    async getAppSettings() {
      const resp = await appSettingsService.get();
      this.modelPayload = resp.data ? resp.data : {};
      this.setSettingToDefault();
    },
    cancelEdit() {
      this.editing = false;
      this.setSettingToDefault();
    },
    setSettingToDefault() {
      this.model = new AppSettingsConstructor(this.modelPayload);
    },
    confirmSaveSettings() {
      this.$showConfirmWarningDialog({
        title: this.$t('$Dialogs.ConfirmUpdateTitle'),
        text: this.$t('ConfirmSaveText'),
        accept: () => {
          this.saveSettings();
        },
        acceptText: this.$t('$General.Update'),
      });
    },
    async saveSettings() {
      this.$vs.loading({ type: 'radius' });
      const resp = await appSettingsService.update(this.model.toSavePayload());
      this.modelPayload = resp.data ? resp.data : {};
      this.$vs.loading.close();

      this.$showSuccessActionNotification({
        title: this.$tc('$Notifications.UpdateTitle', 2, { entity: this.$tc(`$Entities.${this.entity}`) }),
        text: this.$tc('$Notifications.UpdateMsg', 2, { entity: this.$tc(`$Entities.${this.entity}`) }),
      });

      this.editing = false;
    },
  },
};
</script>

<style lang="scss">
#the-app-settings-packages .vs-con-tbody.vs-table--tbody {
  border: none;
}
</style>
