/**
 * Create a new app settings
 * @class AppSettingsConstructor
 */
export default class AppSettingsConstructor {
  usdExchangeRate = null;

  monthlyDiscountServices = null;

  gst = null;

  packages = [];

  providersSwitch = {};

  constructor(payload = {}) {
    this.usdExchangeRate = payload.usdExchangeRate || 0;
    this.monthlyDiscountServices = payload.monthlyDiscountServices || 0;
    this.gst = payload.gst || 0;
    this.stopSendingCampaigns = payload.stopSendingCampaigns || false;

    if (payload.packages && Array.isArray(payload.packages) && payload.packages.length > 0) {
      this.packages = payload.packages.map((p) => p.price);
    } else {
      this.packages = [20, 50, 100, 200, 500, 1000];
    }

    if (payload.providersSwitch
      && typeof payload.providersSwitch === 'object'
      && Object.keys(payload.providersSwitch).length > 0) {
      this.providersSwitch = payload.providersSwitch;
    }
  }

  toSavePayload() {
    return {
      usdExchangeRate: this.usdExchangeRate,
      monthlyDiscountServices: this.monthlyDiscountServices,
      gst: this.gst,
      stopSendingCampaigns: this.stopSendingCampaigns,
      packages: this.packages,
      providersSwitch: this.providersSwitch,
    };
  }
}
